import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-odoo',
  templateUrl: './odoo.component.html',
  styleUrls: ['./odoo.component.scss']
})
export class OdooComponent implements OnInit {

  constructor() { }
  scrollToDownload(element: any) {
    element.scrollIntoView({ behavior: "smooth" });
  }
  ngOnInit(): void {
  }

}
