import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { IndexComponent } from "./pages/index/index.component";
import { ProfilepageComponent } from "./pages/examples/profilepage/profilepage.component";
import { RegisterpageComponent } from "./pages/examples/registerpage/registerpage.component";
import { LandingpageComponent } from "./pages/examples/landingpage/landingpage.component";
import { HomeComponent } from "./components/home/home.component";
import { ProductosComponent } from "./components/productos/productos.component";
import { QtrackComponent } from './components/qtrack/qtrack.component';
import { QdocComponent } from './components/qdoc/qdoc.component';
import { QscreenComponent } from './components/qscreen/qscreen.component';
import { QbiometricComponent } from './components/qbiometric/qbiometric.component';
import { FullstockComponent } from './components/fullstock/fullstock.component';
import { OdooComponent } from './components/odoo/odoo.component';

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "home", component: HomeComponent },
  { path: "profile", component: ProfilepageComponent },
  { path: "register", component: RegisterpageComponent },
  { path: "landing", component: LandingpageComponent },
  { path: "productos", component: ProductosComponent },
  { path: "qtrack", component: QtrackComponent },
  { path: "qdoc", component: QdocComponent },
  { path: "qscreen", component: QscreenComponent },
  { path: "qbiometric", component: QbiometricComponent },
  { path: "fullstock", component: FullstockComponent },
  { path: "odoo", component: OdooComponent },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: []
})
export class AppRoutingModule {}
