import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qscreen',
  templateUrl: './qscreen.component.html',
  styleUrls: ['./qscreen.component.scss']
})
export class QscreenComponent implements OnInit {

  constructor() { }
  scrollToDownload(element: any) {
    element.scrollIntoView({ behavior: "smooth" });
  }


  ngOnInit(): void {
  }

}
