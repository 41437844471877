import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qdoc',
  templateUrl: './qdoc.component.html',
  styleUrls: ['./qdoc.component.scss']
})
export class QdocComponent implements OnInit {

  constructor() { }
  scrollToDownload(element: any) {
    element.scrollIntoView({ behavior: "smooth" });
  }

  ngOnInit(): void {
  }

}
