import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fullstock',
  templateUrl: './fullstock.component.html',
  styleUrls: ['./fullstock.component.scss']
})
export class FullstockComponent implements OnInit {

  constructor() { }
  scrollToDownload(element: any) {
    element.scrollIntoView({ behavior: "smooth" });
  }

  ngOnInit(): void {
  }

}
